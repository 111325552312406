<template>
  <b-container fluid>
    <b-row>
      <b-col class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
         <div class="wrapper datepickerCalendar mb-2" style="width: 100%;">
            <flat-pickr v-model="bookedSpcmSessions" :config="flatpickrConfig"></flat-pickr>
         </div>
      </b-col>
      <b-col class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
      <template>
        <div class="iq-card current_event">
          <div class="iq-card-header d-flex justify-content-between">
              <div class="iq-header-title">
                <h4 class="card-title">Today's Schedule
                    <i title="new" class="pt-5px fa-solid fa-circle-plus primary-color pointer mr-1 todayAdd" @click="showAddSession()"></i>
                </h4>
              </div>
          </div>
          <div class="iq-card-body">
              <ul class="m-0 p-0 today-schedule">
                <li class="d-flex">
                    <div class="schedule-icon"><i class="ri-checkbox-blank-circle-fill text-primary"></i></div>
                    <div class="schedule-text"> <span>Web Design</span>
                      <span>09:00 to 12:00</span>
                    </div>
                </li>
                <li class="d-flex">
                    <div class="schedule-icon"><i class="ri-checkbox-blank-circle-fill text-success"></i></div>
                    <div class="schedule-text"> <span>Participate in Design</span>
                      <span>09:00 to 12:00</span>
                    </div>
                </li>
              </ul>
          </div>
        </div>
        <ValidationObserver ref="admissionForm">
          <form class="mt-3 iq-card p-2 session_card" @submit.prevent="onSubmit" v-if="showHideAddSession">
              <div class="form-row">
              <div class="col-md-12 mb-3" v-if="counsellorView">
                <!-- Session Title -->
                <template>
                  <div class="col-md-12 mb-3">
                      <label for="validation_chooseDate">Session Title</label>
                      <input
                      v-model="vmSessionForm.session_title"
                      type="text"
                      class="form-control"
                      id="validation_session_title"
                    />
                  </div>
                </template> <!-- Session Title -->

                <label for="session_hosting_platform s-heading">Hosting PlatForm</label>
                <select
                  v-model="vmSessionForm.session_hosting_platform"
                  class="form-control"
                  :disabled="!counsellorView"
                  id="session_hosting_platform"
                >
                  <option
                    v-for="(platform, index) in SPCMSessionHostingPlatForm"
                    :key="index"
                    :value="platform"
                  >
                    {{ platform }}
                  </option>
                </select>
              </div>
              <template v-if="vmSessionForm.session_hosting_platform != SPCMSessionHostingPlatForm.ZOOM">
                <div class="col-md-12 mb-3">
                  <label for="validation_chooseDate">Session Meeting Weblink</label>
                  <input
                    v-model="vmSessionForm.session_weblink"
                    type="text"
                    class="form-control"
                    id="validation_session_weblink"
                  />
                </div>
              </template>

              <div class="col-md-6 mb-3" v-if="!counsellorView">
                <label for="validation_chooseDate">Counsellor</label>
                  <multiselect
                    v-model="vmSessionForm['counsellor']"
                    :options="cvCounsellorOptions"
                    placeholder="Search"
                    label="user_name"
                    @select="getAvailableSlots"
                    track-by="user_name">
                </multiselect>
              </div>
              <div class="col-md-6 mt-5" v-if="vmSessionForm['counsellor'] && !counsellorView">
                <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                  <div class="iq-card-body">
                      <div class="iq-badges text-left">
                        <div class="badges-icon">
                          <img class="avatar-80" style="border-radius:50%" v-if="vmSessionForm['counsellor'].user_image" :src="vmSessionForm['counsellor'].user_image" alt="">
                          <div v-else class="avatar-80" style="text-align: center; background-color: #EEEEEE;">
                            <span style="margin: auto; font-size: 50px;">{{vmSessionForm['counsellor'].user_name.slice(0,1)}}</span>
                          </div>
                        </div>
                        <h5 class="mb-2">{{vmSessionForm['counsellor'].user_name}}</h5>
                        <p>{{vmSessionForm['counsellor'].user_desc}}</p>
                        <span class="text-uppercase">
                          Price: {{vmSessionForm['counsellor'].subs_plan_price}} | Gide Plus Price: {{vmSessionForm['counsellor'].subs_plan_price_gideplus}}
                        </span>
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-3" id="choose_slot_box">
                <label for="validation_chooseDate">Choose Slot&nbsp;&nbsp;<i class="fa-solid fa-circle-plus pull-right primary-color pointer pt-5px add_btn" title="Add More Slots" @click="spcmCalendarAdd"></i></label>
                <multiselect
                  v-model="vmSessionForm.slot"
                  class=""
                  style="font-size: x-small;"
                  :options="availableSlotList"
                  :custom-label="sessionCustomLabel"
                  id="validation_chooseDate">
                </multiselect>
              </div>
              <div class="col-md-12">
                <b-button size="sm" variant="primary" class="pull-right primary mb-3 ml-1" v-if="counsellorView" @click="showSessionListFn()">
                  Cancel
                </b-button>
                <b-button size="sm" variant="primary" class="pull-right primary mb-3 ml-1" @click="addSession()">
                  {{vmSessionForm.session_id ? 'Edit' : 'Add'}}
                </b-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </template>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelSpcmCalenderAdd"
      scrollable
      :title="cvAddModalHeader"
      size="lg"
    >
      <SpcmCalenderAdd :propOpenedInModal="true" @emitCloseSpcmCalenderAddModal="closeSpcmCalenderAddModal"/>
      <template #modal-footer="">
        <b-button size="sm" @click="closeSpcmCalenderAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script src="https://cdn.jsdelivr.net/npm/flatpickr"></script>
<script>
import "vue-datetime/dist/vue-datetime.css"
import { Datetime } from "vue-datetime"
import { socialvue } from "../config/pluginInit.js"
import { SpcmSessions } from "../FackApi/api/spcmSession.js"
import SPCMSessionHostingPlatForm from "../FackApi/json/SPCMSessionHostingPlatForm.json"
import { User } from "../FackApi/api/user.js"
import { SpcmCalenders } from "../FackApi/api/SpcmCalender.js"
import SpcmCalenderAdd from "../../src/views/Gide/SPCM/SpcmCalenderAdd.vue"
import Multiselect from "vue-multiselect"
import moment from "moment"

export default {
  name: "SpcmList",
  components: {
    Datetime,
    Multiselect,
    SpcmCalenderAdd
  },
  props: {
    propSpcmId: {
      default: null
    },
    propSessionId: {
      default: null
    },
    propSpcmObj: {
      default: null
    },
    propViewList: {
      default: false
    },
    propStudentData: {
      default: null
    }
  },
  data () {
    return {
      counsellorView: true,
      vmSessionForm: {
        session_hosting_platform: SPCMSessionHostingPlatForm.ZOOM
      },
      SPCMSessionHostingPlatForm: SPCMSessionHostingPlatForm,
      cvCounsellorOptions: [],
      availableSlotList: [],
      cvAddModalHeader: "Add Calender",
      showModelSpcmCalenderAdd: false,
      cvbtnModalCancel: "Close",
      showHideAddSession: false,
      selectedDate: null,
      bookedSpcmSessions: [], // Pre-selected dates
      flatpickrConfig: {
        dateFormat: "Y-m-d",
        mode: "multiple", // Allow multiple date selection
        inline: true, // Always show calendar
        onChange: this.getSpcmSessionsForDate
      },
      whereFilter: {}
  }},
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  mounted () {
    socialvue.index()
    this.spcmSessionList()
    this.getCounsellorList ()
    this.getAvailableSlots ()
  },
  methods: {
    async getSpcmSessionsForDate (selectedDates, dateStr, instance) {
      try {
        let getSessionsForDate = null;
        for (let date of dateStr.split(", ")) {
          let isDateAlreadySelected = this.bookedSpcmSessions.split(",").find(e => {
            return e == date
          })
          if (!isDateAlreadySelected) {
            getSessionsForDate = date
          }
        }
      }
      catch (err) {
        console.error("Exception in getSpcmSessionsForDate() and err: ", err.message)
      }
    },
    formatDate(date) {
      return new Date(date).toISOString().split("T")[0]; // Format date to 'YYYY-MM-DD'
    },
    /* eslint-disable */
    sessionCustomLabel ({ slot_start, slot_end }) {
      return `${slot_start} - ${slot_end}`
    },
    /**
     * addSession
     */
     async addSession () {
      let addSessionApi = true

      if (this.vmSessionForm.counsellor && this.vmSessionForm.counsellor.user_id) {
        // Session is not mandatory, so if a session is not added, create a session with the current timestampS
        if (!this.vmSessionForm.slot) {
          this.vmSessionForm.slot = {
            spcm_calendar_id: "SPCM" + Math.floor(100000 + Math.random() * 900000), // Generating a random spcm_calendar_id
            slot_start_ts: moment().unix(), // setting the slot start time to current timestamp
            slot_duration: 3600000 // default duration of 1hr
          }
        }

        let payload = {
          session_id: this.vmSessionForm.session_id,
          session_title: this.vmSessionForm.session_title ? this.vmSessionForm.session_title : `Session ${this.sessionList.length + 1}`,
          spcm_id: this.propSpcmId,
          session_start_ts: this.vmSessionForm.slot.slot_start_ts,
          session_end_ts: Number(this.vmSessionForm.slot.slot_start_ts) + this.vmSessionForm.slot.slot_duration,
          session_hosting_platform: this.vmSessionForm.session_hosting_platform,
          session_weblink: this.vmSessionForm.session_weblink,
          counsellor_id: this.vmSessionForm.counsellor.user_id,
          spcm_calendar_id: this.vmSessionForm.slot.spcm_calendar_id,
          session_state: null,
          user_email: this.propStudentData.user_email,
          user_id: this.vmSessionForm.counsellor.user_id,
          participant_id: this.propStudentData.user_id
        }

        let apiResp = null
        if (this.counsellorView && this.vmSessionForm.session_id) {
          addSessionApi = false
          apiResp = await SpcmSessions.spcmSessionEdit(this, payload)
        }
        else {
          if (this.propSpcmObj.spcm_total_session < 2) {
            payload.session_state = 0
          }
          else {
            payload.session_state = 2 // Sessionn confirmed
          }
          addSessionApi = true
          apiResp = await SpcmSessions.spcmSessionAdd(this, payload)
        }

        ApiResponse.responseMessageDisplay(this, apiResp)

        if (apiResp.resp_status) {
          this.showSessionList = true
          this.getSessionList()
        }
        else if (apiResp.resp_code === "ERR_SUBS_PLAN_PURCHASE_REQUIRED" || apiResp.resp_code === "ERR_SUBS_PLAN_LIMIT_OVER") {
          this.showPaymentModal = true
          this.showSessionList = true
          this.newSessionId = apiResp.resp_data.session_id
          return
        }

        if (addSessionApi) {
          this.$emit("emitSessionUpdated", apiResp.resp_data)
          this.sessionList.push(apiResp.resp_data)
          this.updateDom += 1
        }
      }
      else {
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "Counsellor is not selected for the session"
      }
    },
    /**
     * getCounsellorList
     */
    async getCounsellorList () {
      const payload = {
        user_role: "USERROLE11118" // counsellor
      }
      const apiResp = await User.userListSearch(this, payload)
      if (apiResp.resp_status) {
        this.cvCounsellorOptions = apiResp.resp_data.data
      }
    },
    /**
     * spcmCalendarAdd
     */
    spcmCalendarAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/spcmCalendar_add")
        }
        else {
          this.showModelSpcmCalenderAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmCalendarAdd() and Exception:", err.message)
      }
    },
    /**
     * getAvailableSlots
    */
    async getAvailableSlots (counsellor) {
      this.availableSlotList = []
      let payload = {
        counsellor_id: counsellor.user_id
      }
      this.counsellorId = counsellor.user_id

      const sessionListApiResp = await SpcmCalenders.spcmCalendarList(this, payload)

      if (sessionListApiResp.resp_status) {
        this.availableSlotList = sessionListApiResp.resp_data.data

        if (this.vmSessionForm.slot && typeof this.vmSessionForm.slot == "number") {
          const findIndex = this.availableSlotList.findIndex(ele => ele.slot_start_ts == this.vmSessionForm.slot)
          if (findIndex >= 0) {
            this.vmSessionForm.slot = this.availableSlotList[findIndex]
          }
        }
      }
    },
    /**
     * closeSpcmCalenderAddModal
     */
    closeSpcmCalenderAddModal () {
      let counsellor = {
        counsellor_id: this.propSpcmObj.counsellor_id,
        user_id: this.propSpcmObj.counsellor_id
      }
      this.getAvailableSlots(counsellor)
      this.showModelSpcmCalenderAdd = false
    },
     /**
     * spcmSessionList
     */
    async spcmSessionList () {
      try {
        this.whereFilter.spcm_id = this.propSpcmId
        let spcmSessionListResp = await SpcmSessions.spcmSessionList(this, this.whereFilter)
        if (spcmSessionListResp.resp_status) {
          this.spcmSessionObjList = spcmSessionListResp.resp_data.data
          this.totalRows = spcmSessionListResp.resp_data.count
        }
        else {
          this.toastMsg = spcmSessionListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }

        this.bookedSpcmSessions = this.spcmSessionObjList.map(session => {
          return moment.unix(session.session_start_ts).format("YYYY-MM-DD")
        })

        // this.bookedSpcmSessions = ["2025-03-26", "2025-03-06", "2025-03-03"]
      }
      catch (err) {
        console.log("Exception occurred at spcmSessionList() and Exception:", err.message)
      }
    },
    showAddSession () {
      this.showHideAddSession = true
    }
  }
}
</script>

<style lang="scss">
div.current_event{
  border: 1px solid #f1f1f1 !important;
  .iq-card-header{
    border-bottom: 1px solid #f1f1f1 !important;
    padding: 0px 10px !important;
  }
  .card-title{
    font-size: 16px;
  }
}
.todayAdd{
  position: absolute;
  right: 0;
}
.session_card{
  border: 1px solid #f1f1f1 !important;
}
</style>
