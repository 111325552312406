/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <div>
            <ValidationObserver ref="inviteCounsellor">
              <form action="#">
                <div class="form-row">
                  <div class="col-md-12 mb-3">
                    <label for="validationcounsellor_user_id">{{cvCounsellorUserIdLabel}}</label><label class="primary-color font-size-16">*</label>
                      <ValidationProvider :name="cvCounsellorUserIdLabel" v-slot="{ errors }">
                        <select v-model="vmSpcmCounsellorFormData.counsellor_user_id" class="form-control"  style="text-transform: capitalize;">
                          <option  v-for="(counselor_Id, index) of userCounsellorObjList" :key="(index+1)" :value="counselor_Id.user_id" >
                            {{counselor_Id.user_name}}
                          </option>
                        </select>
                      <span class="text-danger"> {{ errors[0]}} </span>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationrole_desc">{{cvRoleDesLabel}}</label><label class="primary-color font-size-16">*</label>
                      <ValidationProvider :name="cvRoleDesLabel" v-slot="{ errors }">
                        <input v-model="vmSpcmCounsellorFormData.role_desc" type="text" class="form-control" required/>
                        <span class="text-danger"> {{ errors[0]}} </span>
                      </ValidationProvider>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationjob_desc">{{cvJobDescLabel}}</label><label class="primary-color font-size-16">*</label>
                      <ValidationProvider :name="cvJobDescLabel" v-slot="{ errors }">
                      <textarea v-model="vmSpcmCounsellorFormData.job_desc" type="text" class="form-control textarea" required></textarea>
                      <span class="text-danger"> {{ errors[0]}} </span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-group">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                    <b-spinner label="Spinning"></b-spinner>
                  </button>
                  <button type="button" class="btn btn-primary" @click="spcmCounsellorAdd()">
                    {{ cvSubmitBtn }}
                  </button>
                </div>
              </form>
            </ValidationObserver>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { SpcmCounsellors } from "../../../FackApi/api/SpcmCounsellor"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"
import { User } from "../../../FackApi/api/user.js"
import { ValidationProvider } from "vee-validate"

export default {
  name: "SpcmCounsellorAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add Counselor",
      cvSubmitBtn: "Add",
      cvCounsellorUserIdLabel: "Select Members to Add to your team",
      cvRoleDesLabel: "Role",
      cvJobDescLabel: "Bio",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Counselor Added",
      vmSpcmCounsellorFormData: Object.assign({}, this.initFormData()),
      vmSpcmCounsellorDesc: null,
      vmSpcmCounsellorType: null,
      userCounsellorObjList: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 7)), // in Last 7 Days
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        },
        user_role: "USERROLE11118"
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  components: {
    ValidationProvider
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role != "USERROLE11111" && this.userData.user_role != "USERROLE11118") {
      this.$router.back()
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.userCounsellorList()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "counsellor_user_id": "",
        "role_desc": "",
        "job_desc": ""
      }
    },
    /**
     * spcmCounsellorAdd
     */
    async spcmCounsellorAdd () {
      // Loop through this.userCounsellorObjList and get the user name and set it in the this.vmSpcmCounsellorFormData

      let valid = await this.$refs.inviteCounsellor.validate()
      if (!valid) {
        return
      }

      // If Name found
      for (let userData of this.userCounsellorObjList) {
        if (userData.user_id == this.vmSpcmCounsellorFormData.counsellor_user_id) {
          this.vmSpcmCounsellorFormData.counsellor_user_id = userData.user_id // this.userCounsellorObjList[i].user_name
          this.vmSpcmCounsellorFormData.user_email = userData.user_email // this.userCounsellorObjList[i].user_email
          this.vmSpcmCounsellorFormData.user_name = userData.user_name // this.userCounsellorObjList[i].user_email
        }
      }
      try {
        this.cvLoadingStatus = true
        let spcmCounsellorAddResp = await SpcmCounsellors.spcmCounsellorAdd(this, this.vmSpcmCounsellorFormData)
        await ApiResponse.responseMessageDisplay(this, spcmCounsellorAddResp)
        if (spcmCounsellorAddResp && !spcmCounsellorAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSpcmCounsellorAddModal", this.vmSpcmCounsellorFormData)
        }

        this.vmSpcmCounsellorFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at spcmCounsellorAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
    * userCounsellorList
    */
    async userCounsellorList () {
      try {
        let userCounsellorListResp = await User.userCounsellorList(this, this.whereFilter)
        if (userCounsellorListResp.resp_status) {
          this.userCounsellorObjList = userCounsellorListResp.resp_data.data
          this.totalRows = userCounsellorListResp.resp_data.count
        }
        else {
          this.userCounsellorObjList = []
          this.toastMsg = userCounsellorListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at userCounsellorList() and Exception:", err.message)
      }
    }
  }
}
</script>
<style scoped>
.textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
